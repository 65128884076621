import React, { Fragment } from 'react'
import MediaQuery from 'react-responsive'
import { withViewer } from '../contexts'

React.createContext(null)

const BottomBannerAd = withViewer(({ premium, onClose, width, height }) => (
  <a href={'https://www.playingfire.com/buy-product?utm_source=forums'}>
    <div
      className="flex justify-center fixed bottom-0"
      style={{ width: '100%', height }}
    >
      <div className="relative" style={{ width, height }}>
        <img
          width={width}
          height={height}
          src={
            premium
              ? 'https://firebasestorage.googleapis.com/v0/b/forums-app-dev-1547324904040.appspot.com/o/upgrade-bottom.webp?alt=media&token=0e5d6e58-319a-4e56-a1d1-e2d779643a36'
              : 'https://firebasestorage.googleapis.com/v0/b/forums-app-dev-1547324904040.appspot.com/o/new-bottom.webp?alt=media&token=d640e88f-cb9e-403e-be9a-b42e54991241'
          }
          alt="Banner advertisement"
          className="object-fit-contain"
        />
        <img
          onClick={e => {
            e.preventDefault()
            onClose()
          }}
          className="absolute top-0 right-0 pointer"
          src="https://i.imgur.com/kqyQhm0.png"
          alt="close"
          style={{
            marginRight: 4
          }}
          height={22}
          width={22}
        />
      </div>
    </div>
  </a>
))

export default ({ onClose }) => (
  <Fragment>
    <MediaQuery minWidth={768}>
      <BottomBannerAd onClose={onClose} width={750} height={80} />
    </MediaQuery>
    <MediaQuery maxWidth={767}>
      <BottomBannerAd onClose={onClose} width={350} height={54} />
    </MediaQuery>
  </Fragment>
)
